import React, { useState } from "react";
import { TextField, Checkbox, FormControlLabel } from "@mui/material";
import styled from "styled-components";

interface UserFormProps {
  onSubmit: (data: FormData) => void;
}

interface FormData {
  email: string;
  phoneNumber?: string;
  name?: string;
  isAgreed: boolean;
}

const UserForm: React.FC<UserFormProps> = ({ onSubmit }) => {
  const [formData, setFormData] = useState<FormData>({
    email: "",
    phoneNumber: "",
    name: "",
    isAgreed: false,
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      isAgreed: checked,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (formData.isAgreed) {
      onSubmit(formData);
    } else {
      alert("Please agree to the terms before submitting.");
    }
  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      <InputGroup>
        <StyledTextField
          label="Email"
          type="email"
          name="email"
          required
          value={formData.email}
          onChange={handleInputChange}
          fullWidth
          variant="outlined"
          InputProps={{
            sx: {
              borderRadius: "12px",
              backgroundColor: "rgba(81, 103, 246, 0.02)",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(81, 103, 246, 0.1)",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(81, 103, 246, 0.2)",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#5167F6",
              },
            },
          }}
        />
        <StyledTextField
          label="Phone Number"
          type="tel"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleInputChange}
          fullWidth
          variant="outlined"
          InputProps={{
            sx: {
              borderRadius: "12px",
              backgroundColor: "rgba(81, 103, 246, 0.02)",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(81, 103, 246, 0.1)",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(81, 103, 246, 0.2)",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#5167F6",
              },
            },
          }}
        />
        <StyledTextField
          label="Name"
          type="text"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          fullWidth
          variant="outlined"
          InputProps={{
            sx: {
              borderRadius: "12px",
              backgroundColor: "rgba(81, 103, 246, 0.02)",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(81, 103, 246, 0.1)",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(81, 103, 246, 0.2)",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#5167F6",
              },
            },
          }}
        />
      </InputGroup>

      <BottomSection>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.isAgreed}
              onChange={handleCheckboxChange}
              sx={{
                color: "rgba(81, 103, 246, 0.6)",
                "&.Mui-checked": {
                  color: "#5167F6",
                },
              }}
            />
          }
          label={
            <TermsText>
              I agree to the{" "}
              <TermsLink href="https://rombis.ai/terms-of-use" target="_blank">
                Terms of Use
              </TermsLink>
            </TermsText>
          }
        />
        <SubmitButton
          type="submit"
          disabled={!formData.email || !formData.isAgreed}
        >
          Submit
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            style={{ marginLeft: "8px" }}
          >
            <path
              d="M3.33337 8H12.6667M12.6667 8L8.00004 3.33333M12.6667 8L8.00004 12.6667"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </SubmitButton>
      </BottomSection>
    </StyledForm>
  );
};

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledTextField = styled(TextField)`
  & .MuiInputLabel-root {
    color: #666;
    font-size: 15px;
  }

  & .MuiInputLabel-root.Mui-focused {
    color: #5167f6;
  }
`;

const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const TermsText = styled.p`
  margin: 0;
  font-size: 14px;
  color: #666;
`;

const TermsLink = styled.a`
  color: #5167f6;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.2s ease;

  &:hover {
    color: #7186ff;
    text-decoration: underline;
  }
`;

const SubmitButton = styled.button`
  background: linear-gradient(90deg, #5167f6 0%, #7186ff 100%);
  color: white;
  border: none;
  border-radius: 12px;
  padding: 12px 24px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;

  &:hover:not(:disabled) {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(81, 103, 246, 0.2);
  }

  &:disabled {
    background: linear-gradient(90deg, #e2e8f0 0%, #edf2f7 100%);
    color: #a0aec0;
    cursor: not-allowed;
  }
`;

export default UserForm;
