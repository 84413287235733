import styled from "styled-components";

export const Root = styled.div`
  padding: 80px 24px;
  background: linear-gradient(180deg, #f8f9ff 0%, #ffffff 100%);
`;

export const PricingContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
`;

export const Title = styled.h2`
  font-size: 40px;
  font-weight: 800;
  color: #2a2a2a;
  margin-bottom: 24px;
  letter-spacing: -1px;

  @media (max-width: 768px) {
    font-size: 32px;
  }
`;

export const Description = styled.p`
  font-size: 18px;
  color: #666;
  max-width: 700px;
  margin: 0 auto 64px;
  line-height: 1.6;
`;

export const BenefitsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 32px;
  padding: 24px;
`;

export const BenefitCard = styled.div`
  background: white;
  padding: 32px;
  border-radius: 24px;
  text-align: left;
  transition: all 0.3s ease;
  border: 1px solid rgba(81, 103, 246, 0.08);
  box-shadow: 0 4px 20px rgba(81, 103, 246, 0.06);

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 30px rgba(81, 103, 246, 0.1);
  }

  .icon-wrapper {
    background: rgba(81, 103, 246, 0.04);
    width: 64px;
    height: 64px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
  }

  h3 {
    font-size: 20px;
    font-weight: 700;
    color: #2a2a2a;
    margin: 0 0 12px 0;
  }

  p {
    font-size: 15px;
    color: #666;
    line-height: 1.6;
    margin: 0;
  }
`;
