import styled from "styled-components";

export const Root = styled.div``;

export const HeroWrapper = styled.div`
  background: linear-gradient(
    180deg,
    rgba(81, 103, 246, 0.03) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    background: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M54 28h6v4h-6v-4zm-6-14h6v4h-6v-4zm-6 0h6v4h-6v-4zm-6 14h6v4h-6v-4zm-6-14h6v4h-6v-4zm-6 0h6v4h-6v-4zm-6 14h6v4h-6v-4zm-6-14h6v4h-6v-4zm-6 0h6v4h-6v-4zm-6 14h6v4h-6v-4zm-6-14h6v4h-6v-4zm-6 0h6v4h-6v-4zm-6 14h6v4h-6v-4z' fill='%235167F6' fill-opacity='0.03' fill-rule='evenodd'/%3E%3C/svg%3E");
    opacity: 0.4;
    animation: subtle-move 60s linear infinite;
    pointer-events: none;
  }

  @keyframes subtle-move {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
