import { FunctionComponent } from "react";
import {
  Root,
  PricingContainer,
  Title,
  Description,
  BenefitsGrid,
  BenefitCard,
} from "./Pricing.styled";
import SearchIcon from "@mui/icons-material/Search";
import BarChartIcon from "@mui/icons-material/BarChart";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import SavingsIcon from "@mui/icons-material/Savings";

const Pricing: FunctionComponent = () => (
  <Root>
    <PricingContainer id="pricing">
      <Title>
        Powerful Analysis{" "}
        <span
          style={{
            background: "linear-gradient(90deg, #5167F6 0%, #7186FF 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          100% Free
        </span>
      </Title>
      <Description>
        Join thousands of businesses who have discovered hidden savings in their
        processing fees. Our AI-powered analysis helps you understand complex
        statements and make informed decisions.
      </Description>

      <BenefitsGrid>
        <BenefitCard>
          <div className="icon-wrapper">
            <SearchIcon style={{ fontSize: 32, color: "#5167F6" }} />
          </div>
          <h3>Fee Discovery</h3>
          <p>
            Uncover hidden fees and charges in your statement that may be
            costing you money.
          </p>
        </BenefitCard>

        <BenefitCard>
          <div className="icon-wrapper">
            <BarChartIcon style={{ fontSize: 32, color: "#10B981" }} />
          </div>
          <h3>Visual Insights</h3>
          <p>
            Clear charts and breakdowns help you understand where your money is
            going.
          </p>
        </BenefitCard>

        <BenefitCard>
          <div className="icon-wrapper">
            <CompareArrowsIcon style={{ fontSize: 32, color: "#FF6B6B" }} />
          </div>
          <h3>Rate Comparison</h3>
          <p>
            See how your rates compare to industry standards and identify
            potential savings.
          </p>
        </BenefitCard>

        <BenefitCard>
          <div className="icon-wrapper">
            <SavingsIcon style={{ fontSize: 32, color: "#6B7280" }} />
          </div>
          <h3>Savings Calculator</h3>
          <p>
            Get a clear estimate of potential monthly and annual savings
            opportunities.
          </p>
        </BenefitCard>
      </BenefitsGrid>
    </PricingContainer>
  </Root>
);

export default Pricing;
