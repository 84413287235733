import styled from "styled-components";

export const Root = styled.div`
  padding: 100px 24px;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
`;

export const TagLine = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #5167f6;
  margin-bottom: 16px;
  letter-spacing: -0.5px;
  text-transform: uppercase;
  background: linear-gradient(90deg, #5167f6 0%, #7186ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  padding: 8px 16px;
  border-radius: 100px;
  background-color: rgba(81, 103, 246, 0.04);
`;

export const Title = styled.h1`
  font-size: 56px;
  font-weight: 800;
  color: #2a2a2a;
  line-height: 1.1;
  letter-spacing: -1px;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    font-size: 40px;
  }
`;

export const InfoText = styled.p`
  font-size: 20px;
  color: #666;
  max-width: 700px;
  margin: 0 auto 48px;
  line-height: 1.6;
`;

export const FeatureList = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-bottom: 64px;
  flex-wrap: wrap;
  padding: 0 24px;
`;

export const FeatureItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 20px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 100px;
  font-weight: 600;
  color: #2a2a2a;
  border: 1px solid rgba(81, 103, 246, 0.1);
  box-shadow: 0 2px 8px rgba(81, 103, 246, 0.06);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(81, 103, 246, 0.1);
  }

  span {
    font-size: 15px;
  }

  svg {
    font-size: 20px;
  }
`;

export const UploadContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 24px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120%;
    height: 120%;
    background: radial-gradient(
      circle at center,
      rgba(81, 103, 246, 0.04) 0%,
      rgba(255, 255, 255, 0) 70%
    );
    transform: translate(-50%, -50%);
    z-index: -1;
  }
`;

export const UploadArea = styled.div`
  position: relative;
`;
