//@ts-nocheck
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  LabelList,
} from "recharts";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import { isMobile } from "react-device-detect";
import {
  calculateInterchangePercent,
  calculateDuesPercent,
  calculatePassThroughPercent,
  calculateProcessingPercent,
  getBrandVolumeSplit,
  getBrandTransactionSplit,
  getCreditDebitSVolumeSplit,
  getCreditDebitTransactionSplit,
  createCardFeeTableRows,
  createAccountFeesTableRows,
} from "../../utils/report";
import { Root, Tile, Title, TileTitle } from "./Report.styled";
import Navbar from "../../components/Navbar/Navbar";
import { getReportAPI } from "../../api/report";
import LearnHowModal from "../../components/LearnHowModal/LearnHowModal";

function closestLowerHalf(num) {
  // Truncate the number to get the whole part
  var wholeNumber = Math.floor(num);

  // Calculate the decimal part of the number
  var decimal = num - wholeNumber;

  // Check if the decimal part is greater than or equal to 0.5
  if (decimal >= 0.5) {
    return wholeNumber + 0.5;
  } else {
    return wholeNumber;
  }
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.95)",
          padding: "20px",
          border: "1px solid #DEDFE3",
          borderRadius: "12px",
        }}
      >
        <p
          style={{
            fontWeight: "500",
            fontSize: isMobile ? "16px" : "18px",
            color: "rgb(81, 103, 246)",
            margin: "0px",
            padding: "0px",
          }}
        >{`Processing Fees: ${payload[1].value}%`}</p>
        <p style={{ fontSize: isMobile ? "16px" : "18px" }}>
          Fees charged by the payment processor for handling and facilitating
          the transaction.
        </p>
        <p
          style={{
            fontWeight: "500",
            fontSize: isMobile ? "16px" : "18px",
            color: "red",
            margin: "0px",
            padding: "0px",
          }}
        >{`Pass-Through Fees: ${payload[0].value}%`}</p>
        <p style={{ fontSize: isMobile ? "16px" : "18px" }}>
          Fees directly charged by card networks or banks, passed through
          without any markup.
        </p>
      </div>
    );
  }
  return null;
};

const Report = () => {
  const { _report = "" } = useParams();
  const navigate = useNavigate();
  const [report, setReport] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);
  const [showLearnHowModal, setShowLearnHowModal] = useState(false);

  const getReport = async (_report: String, tryCount: Number) => {
    try {
      const reportAPIResponse = await getReportAPI(_report);
      setReport(reportAPIResponse.data);
      setIsLoading(false);
    } catch (e) {
      if (tryCount < 3) {
        setTimeout(() => {
          getReport(_report, tryCount + 1);
        }, 1500);
      } else {
        alert("Report not available");
        navigate("/");
      }
    }
  };

  useEffect(() => {
    if (!_report) {
      navigate("/");
    }
    getReport(_report);
  }, [_report, navigate]);

  if (isLoading) {
    return (
      <div className="mb-5">
        <Navbar />
        <Root style={{ alignItems: "center" }}>
          <CircularProgress />
        </Root>
      </div>
    );
  }

  const { fee_navigator_response } = report;

  const passThruFees = Number(
    calculatePassThroughPercent(fee_navigator_response)
  );
  const processingFees = Number(
    calculateProcessingPercent(fee_navigator_response)
  );
  const effectiveRate =
    Number(fee_navigator_response?.["Effective Rate"]) * 100;

  const feeBreakdownData = {
    name: "Fee Type",
    // 'Interchange': calculateInterchangePercent(fee_navigator_response),
    "Pass-Through Fees": passThruFees,
    // 'Dues & Assessments': calculateDuesPercent(fee_navigator_response),
    "Processing Fees": processingFees,
  };

  let domainMin = 0;
  if (processingFees < 0.5) {
    domainMin = closestLowerHalf(effectiveRate) - 1;
  }

  let tickValues = [];

  if (domainMin) {
    for (
      let i = domainMin;
      i <= passThruFees + processingFees + 0.5;
      i += 0.5
    ) {
      tickValues.push(i);
    }
  } else {
    for (let i = domainMin; i <= passThruFees + processingFees + 1; i += 0.5) {
      tickValues.push(i);
    }
  }

  console.log("tickValues", tickValues, domainMin);

  const handleAgree = () => {
    // Handle agreement logic here
    setShowLearnHowModal(false);
  };

  return (
    <div className="mb-5">
      <Navbar />
      <Root>
        <div>
          <Tile
            style={{
              maxWidth: isMobile ? "100%" : "1400px",
              margin: "0 auto",
              borderRadius: isMobile ? "16px" : "24px",
              border: "1px solid rgba(42, 42, 42, 0.08)",
            }}
          >
            <TileTitle
              style={{
                fontSize: isMobile ? "24px" : "28px",
                fontWeight: "800",
                color: "#2A2A2A",
                letterSpacing: "-0.5px",
                position: "relative",
                paddingBottom: isMobile ? "10px" : "28px",
                marginBottom: isMobile ? "8px" : "36px",
                ":after": {
                  content: '""',
                  position: "absolute",
                  bottom: "0",
                  left: "0",
                  width: "80px",
                  height: "4px",
                  background:
                    "linear-gradient(90deg, #2A2A2A 0%, #4A4A4A 100%)",
                  borderRadius: "2px",
                },
              }}
            >
              Transaction Fee Overview
            </TileTitle>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: "32px",
              }}
            >
              <div
                className="chart"
                style={{
                  maxWidth: "650px",
                  width: "100%",
                  background: "rgba(255, 255, 255, 0.8)",
                  backdropFilter: "blur(12px)",
                  borderRadius: "24px",
                  padding: isMobile ? "16px" : "24px",
                  boxShadow:
                    "0 8px 32px rgba(81, 103, 246, 0.08), 0 2px 4px rgba(81, 103, 246, 0.04)",
                  transition:
                    "all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
                  ":hover": {
                    transform: "translateY(-4px) scale(1.01)",
                    boxShadow:
                      "0 12px 40px rgba(81, 103, 246, 0.12), 0 2px 6px rgba(81, 103, 246, 0.06)",
                  },
                  height: isMobile ? "610px" : "680px",
                  paddingBottom: "150px",
                  display: "block",
                }}
              >
                <div
                  style={{
                    fontSize: isMobile ? "16px" : "18px",
                    fontWeight: "600",
                    width: "100%",
                    padding: isMobile ? "12px 16px" : "16px 24px",
                    background:
                      "linear-gradient(135deg, #F0F3FF 0%, #E9EDFF 100%)",
                    borderRadius: isMobile ? "12px" : "16px",
                    color: "#5167F6",
                    marginBottom: isMobile ? "16px" : "24px",
                  }}
                >
                  Transaction Fee Breakdown
                </div>
                <div
                  style={{
                    position: "absolute",
                    left: "-100px",
                    top: "40%",
                    transform: "rotate(-90deg) translateX(50%)",
                    transformOrigin: "right",
                    fontSize: isMobile ? "16px" : "18px",
                    fontWeight: "500",
                    color: "#666",
                    whiteSpace: "nowrap",
                    display: isMobile ? "none" : "block",
                  }}
                >
                  % Fee per Transaction
                </div>
                <BarChart
                  style={{
                    overflow: "visible",
                  }}
                  width={isMobile ? 320 : 500}
                  height={isMobile ? 380 : 420}
                  data={[feeBreakdownData]}
                  margin={{
                    top: 20,
                    right: isMobile ? 20 : 30,
                    left: isMobile ? 10 : 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid
                    strokeDasharray="3 3"
                    vertical={false}
                    stroke="rgba(81, 103, 246, 0.1)"
                  />
                  <XAxis
                    dataKey="name"
                    axisLine={false}
                    tickLine={false}
                    tick={{
                      fill: "#666",
                      fontSize: isMobile ? "14px" : "16px",
                      fontWeight: 500,
                    }}
                  />
                  <YAxis
                    axisLine={false}
                    tickLine={false}
                    tick={{
                      fill: "#666",
                      fontSize: isMobile ? "14px" : "16px",
                      fontWeight: 500,
                    }}
                  />
                  <Tooltip
                    cursor={{ fill: "rgba(81, 103, 246, 0.05)" }}
                    contentStyle={{
                      background: "rgba(255, 255, 255, 0.95)",
                      border: "1px solid rgba(81, 103, 246, 0.1)",
                      borderRadius: "8px",
                      boxShadow: "0 4px 12px rgba(81, 103, 246, 0.1)",
                      padding: "8px 12px",
                    }}
                  />
                  <Legend
                    verticalAlign="bottom"
                    height={120}
                    content={({ payload }) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "12px",
                          padding: isMobile ? "12px" : "18px",
                          background: "rgba(255, 255, 255, 0.9)",
                          borderRadius: "12px",
                          boxShadow: "0 2px 8px rgba(81, 103, 246, 0.08)",
                          border: "1px solid rgba(81, 103, 246, 0.1)",
                          marginTop: "24px",
                          width: isMobile ? "calc(100% - 40px)" : "100%",
                        }}
                      >
                        {payload.map((entry, index) => (
                          <div
                            key={`item-${index}`}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "8px",
                              padding: isMobile ? "8px 12px" : "12px 16px",
                              borderRadius: "8px",
                              background: "rgba(81, 103, 246, 0.04)",
                              transition: "all 0.2s ease",
                              border: `1px solid ${
                                entry.value === "Processing Fees"
                                  ? "rgba(81, 103, 246, 0.1)"
                                  : "rgba(255, 107, 107, 0.1)"
                              }`,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <div
                                style={{
                                  width: "12px",
                                  height: "12px",
                                  borderRadius: "3px",
                                  background:
                                    entry.value === "Processing Fees"
                                      ? "linear-gradient(180deg, #5167F6 0%, #7186FF 100%)"
                                      : "linear-gradient(180deg, #FF6B6B 0%, #FF8787 100%)",
                                }}
                              />
                              <span
                                style={{
                                  fontSize: isMobile ? "15px" : "16px",
                                  fontWeight: "600",
                                  color:
                                    entry.value === "Processing Fees"
                                      ? "#5167F6"
                                      : "#FF6B6B",
                                }}
                              >
                                {entry.value}
                              </span>
                            </div>
                            <p
                              style={{
                                margin: "0",
                                fontSize: isMobile ? "14px" : "15px",
                                color: "#666",
                                lineHeight: "1.4",
                              }}
                            >
                              {entry.value === "Processing Fees" ? (
                                <>
                                  <span style={{ fontWeight: "700" }}>
                                    Negotiable
                                  </span>{" "}
                                  fees charged by the payment processor.
                                </>
                              ) : (
                                <>
                                  <span style={{ fontWeight: "700" }}>
                                    Non-negotiable
                                  </span>{" "}
                                  fees charged directly by the network.
                                </>
                              )}
                            </p>
                          </div>
                        ))}
                      </div>
                    )}
                  />
                  <Bar
                    dataKey="Pass-Through Fees"
                    fill="url(#passThruGradient)"
                    radius={[4, 4, 0, 0]}
                  >
                    <LabelList
                      dataKey="Pass-Through Fees"
                      offset={5}
                      position="right"
                    />
                  </Bar>
                  <Bar
                    dataKey="Processing Fees"
                    fill="url(#processingGradient)"
                    radius={[4, 4, 0, 0]}
                  >
                    <LabelList
                      dataKey="Processing Fees"
                      offset={5}
                      position="right"
                    />
                  </Bar>
                  <defs>
                    <linearGradient
                      id="processingGradient"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop offset="0%" stopColor="#5167F6" />
                      <stop offset="100%" stopColor="#7186FF" />
                    </linearGradient>
                    <linearGradient
                      id="passThruGradient"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop offset="0%" stopColor="#FF6B6B" />
                      <stop offset="100%" stopColor="#FF8787" />
                    </linearGradient>
                  </defs>
                </BarChart>
              </div>

              <div
                style={{
                  width: "100%",
                  maxWidth: "650px",
                  background: "rgba(255, 255, 255, 0.9)",
                  backdropFilter: "blur(12px)",
                  borderRadius: isMobile ? "16px" : "24px",
                  padding: isMobile ? "20px" : "46px",
                  boxShadow:
                    "0 8px 32px rgba(81, 103, 246, 0.12), 0 2px 6px rgba(81, 103, 246, 0.06)",
                  transition:
                    "all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
                  border: "1px solid rgba(81, 103, 246, 0.12)",
                  ":hover": {
                    transform: isMobile
                      ? "none"
                      : "translateY(-4px) scale(1.02)",
                    boxShadow: isMobile
                      ? "0 8px 32px rgba(81, 103, 246, 0.12)"
                      : "0 16px 48px rgba(81, 103, 246, 0.16), 0 2px 8px rgba(81, 103, 246, 0.08)",
                  },
                }}
              >
                <div
                  style={{
                    fontSize: isMobile ? "24px" : "32px",
                    fontWeight: "800",
                    background:
                      "linear-gradient(90deg, #5167F6 0%, #7186FF 70%, #8B9FFF 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    marginBottom: isMobile ? "12px" : "16px",
                    letterSpacing: "-0.5px",
                    animation: "pulse 2s infinite",
                  }}
                >
                  $
                  {report?.fee_navigator_response?.[
                    "Processing Fees"
                  ].toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}
                  <span
                    style={{
                      fontSize: isMobile ? "14px" : "18px",
                      fontWeight: "600",
                      marginLeft: isMobile ? "8px" : "12px",
                      opacity: 0.9,
                      color: "#666",
                      WebkitTextFillColor: "#666",
                      background: "#F5F7FF",
                      padding: isMobile ? "2px 8px" : "4px 12px",
                      borderRadius: "20px",
                      display: isMobile ? "inline-block" : "inline",
                      marginTop: isMobile ? "8px" : "0",
                    }}
                  >
                    {calculateProcessingPercent(fee_navigator_response)}% markup
                  </span>
                </div>
                <div
                  style={{
                    color: "#666",
                    borderBottom: "1px solid rgba(81, 103, 246, 0.15)",
                    paddingBottom: isMobile ? "16px" : "24px",
                    marginBottom: isMobile ? "16px" : "24px",
                    fontSize: isMobile ? "14px" : "16px",
                    fontWeight: "700",
                  }}
                >
                  in monthly processing fees
                </div>
                <div
                  style={{
                    background: "rgba(81, 103, 246, 0.04)",
                    padding: isMobile ? "16px" : "20px 24px",
                    borderRadius: isMobile ? "12px" : "16px",
                    color: "#5167F6",
                    fontSize: isMobile ? "13px" : "15px",
                    display: "flex",
                    alignItems: "center",
                    gap: isMobile ? "8px" : "12px",
                    border: "1px solid rgba(81, 103, 246, 0.15)",
                    marginBottom: isMobile ? "16px" : "20px",
                    transition: "all 0.3s ease",
                    cursor: "pointer",
                    position: "relative",
                    overflow: "hidden",
                    flexWrap: isMobile ? "wrap" : "nowrap",
                    ":hover": {
                      transform: isMobile ? "none" : "translateX(4px)",
                      background:
                        "linear-gradient(135deg, #E9EDFF 0%, #E4E9FF 100%)",
                    },
                  }}
                >
                  <span
                    style={{
                      fontWeight: "800",
                      letterSpacing: "0.5px",
                      textTransform: "uppercase",
                      fontSize: isMobile ? "12px" : "inherit",
                    }}
                  >
                    ROMBIS TIP
                  </span>
                  Your processing fees can be negotiated lower.
                </div>

                {report?.fee_navigator_response?.["Monthly Savings"] > 0 && (
                  <div
                    style={{
                      background:
                        "linear-gradient(135deg, rgba(52, 211, 153, 0.04) 0%, rgba(52, 211, 153, 0.08) 100%)",
                      padding: isMobile ? "16px" : "24px",
                      borderRadius: isMobile ? "12px" : "16px",
                      border: "1px solid rgba(52, 211, 153, 0.12)",
                      display: "flex",
                      flexDirection: "column",
                      gap: isMobile ? "12px" : "16px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "24px",
                        fontWeight: "800",
                        color: "#10B981",
                        lineHeight: "1",
                        marginBottom: "12px",
                      }}
                    >
                      Rombis Estimate
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                      }}
                    >
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        style={{
                          animation:
                            "pulseCheck 2s cubic-bezier(0.4, 0, 0.6, 1) infinite",
                          filter:
                            "drop-shadow(0 2px 4px rgba(16, 185, 129, 0.2))",
                        }}
                      >
                        <style>
                          {`
                            @keyframes pulseCheck {
                              0%, 100% {
                                transform: scale(1);
                                opacity: 1;
                              }
                              50% {
                                transform: scale(1.1);
                                opacity: 0.8;
                              }
                            }
                          `}
                        </style>
                        <circle
                          cx="20"
                          cy="20"
                          r="19"
                          stroke="#10B981"
                          strokeWidth="2"
                          fill="rgba(16, 185, 129, 0.1)"
                        />
                        <path
                          d="M12 20L17 25L28 14"
                          stroke="#10B981"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <div
                        style={{
                          fontSize: isMobile ? "32px" : "40px",
                          fontWeight: "800",
                          color: "#10B981",
                          lineHeight: "1",
                          marginBottom: "12px",
                        }}
                      >
                        {(
                          report?.fee_navigator_response?.["Monthly Savings"]
                        ).toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })}
                        <span style={{ fontSize: "16px", paddingLeft: "8px" }}>
                          in monthly savings
                        </span>
                      </div>
                    </div>
                    <button
                      style={{
                        background:
                          "linear-gradient(90deg, #34D399 0%, #10B981 100%)",
                        color: "white",
                        border: "none",
                        borderRadius: isMobile ? "10px" : "12px",
                        padding: isMobile ? "12px 16px" : "14px 24px",
                        fontSize: isMobile ? "14px" : "16px",
                        fontWeight: "600",
                        cursor: "pointer",
                        transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
                        boxShadow: "0 4px 12px rgba(52, 211, 153, 0.2)",
                        alignSelf: "flex-start",
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        position: "relative",
                        overflow: "hidden",
                        "&::before": {
                          content: '""',
                          position: "absolute",
                          top: "0",
                          left: "0",
                          width: "100%",
                          height: "100%",
                          background:
                            "linear-gradient(90deg, transparent 0%, rgba(255, 255, 255, 0.1) 50%, transparent 100%)",
                          transform: "translateX(-100%)",
                          transition:
                            "transform 0.6s cubic-bezier(0.4, 0, 0.2, 1)",
                        },
                        "&:hover": {
                          transform: isMobile
                            ? "none"
                            : "translateY(-2px) scale(1.02)",
                          boxShadow: "0 6px 20px rgba(52, 211, 153, 0.3)",
                          background:
                            "linear-gradient(90deg, #3BD9A0 0%, #0EAD7C 100%)",
                        },
                        "&:hover::before": {
                          transform: "translateX(100%)",
                        },
                      }}
                      onClick={() => setShowLearnHowModal(true)}
                    >
                      Learn How
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        style={{
                          marginLeft: "4px",
                          transition: "transform 0.2s ease",
                        }}
                      >
                        <path
                          d="M3.33337 8H12.6667"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8 3.33331L12.6667 7.99998L8 12.6666"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                )}
              </div>

              <div
                style={{
                  width: "100%",
                  maxWidth: "650px",
                  background: "rgba(255, 255, 255, 0.9)",
                  backdropFilter: "blur(12px)",
                  borderRadius: isMobile ? "16px" : "24px",
                  padding: isMobile ? "20px" : "46px",
                  boxShadow:
                    "0 8px 32px rgba(81, 103, 246, 0.12), 0 2px 6px rgba(81, 103, 246, 0.06)",
                  fontSize: isMobile ? "14px" : "18px",
                  transition:
                    "all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
                  border: "1px solid rgba(81, 103, 246, 0.12)",
                  ":hover": {
                    transform: isMobile
                      ? "none"
                      : "translateY(-4px) scale(1.02)",
                    boxShadow: isMobile
                      ? "0 8px 32px rgba(81, 103, 246, 0.12)"
                      : "0 16px 48px rgba(81, 103, 246, 0.16), 0 2px 8px rgba(81, 103, 246, 0.08)",
                  },
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: isMobile ? "16px" : "24px",
                  }}
                >
                  {[
                    {
                      label: "Transaction Volume",
                      value: `$${fee_navigator_response?.[
                        "Calculated Total Volume"
                      ].toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })}`,
                    },
                    {
                      label: "Total Fees",
                      value: `$${fee_navigator_response?.[
                        "Total Fees"
                      ].toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })}`,
                    },
                    {
                      label: "Effective Rate",
                      value: `${(
                        fee_navigator_response?.["Effective Rate"] * 100
                      ).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })}%`,
                      isLast: true,
                    },
                  ].map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: isMobile ? "6px 8px" : "8px 12px",
                        borderRadius: isMobile ? "8px" : "12px",
                        transition: "all 0.2s ease",
                        background: "transparent",
                        ...(item.isLast && {
                          borderTop: "1px solid rgba(81, 103, 246, 0.15)",
                          paddingTop: isMobile ? "16px" : "24px",
                          marginTop: isMobile ? "4px" : "8px",
                        }),
                        ":hover": {
                          background: "rgba(81, 103, 246, 0.04)",
                          transform: isMobile ? "none" : "translateX(4px)",
                        },
                      }}
                    >
                      <div
                        style={{
                          color: "#666",
                          fontWeight: "500",
                          fontSize: isMobile ? "14px" : "inherit",
                        }}
                      >
                        {item.label}
                      </div>
                      <div
                        style={{
                          fontWeight: "700",
                          color: "#2A2A2A",
                          background: "rgba(81, 103, 246, 0.06)",
                          padding: isMobile ? "2px 8px" : "4px 12px",
                          fontSize: isMobile ? "14px" : "inherit",
                          transition: "all 0.2s ease",
                          ":hover": {
                            background: "rgba(81, 103, 246, 0.1)",
                          },
                        }}
                      >
                        {item.value}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Tile>
          <div className="mt-4">
            <Tile
              style={{
                borderRadius: isMobile ? "16px" : "24px",
                border: "1px solid rgba(81, 103, 246, 0.08)",
              }}
            >
              <TileTitle
                style={{
                  fontSize: isMobile ? "24px" : "28px",
                  fontWeight: "800",
                  color: "#2A2A2A",
                  letterSpacing: "-0.5px",
                  position: "relative",
                  paddingBottom: isMobile ? "10px" : "28px",
                  marginBottom: isMobile ? "8px" : "36px",
                  ":after": {
                    content: '""',
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    width: "80px",
                    height: "4px",
                    background:
                      "linear-gradient(90deg, #2A2A2A 0%, #4A4A4A 100%)",
                    borderRadius: "2px",
                  },
                }}
              >
                Card Fees
              </TileTitle>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: isMobile ? "16px" : "20px",
                  flexFlow: "wrap",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    background: "rgba(255, 255, 255, 0.9)",
                    backdropFilter: "blur(12px)",
                    borderRadius: isMobile ? "16px" : "24px",
                    padding: isMobile ? "20px" : "32px",
                    boxShadow:
                      "0 8px 32px rgba(81, 103, 246, 0.12), 0 2px 6px rgba(81, 103, 246, 0.06)",
                    transition:
                      "all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
                    border: "1px solid rgba(81, 103, 246, 0.12)",
                    width: "100%",
                    maxWidth: isMobile ? "100%" : "800px",
                    ":hover": {
                      transform: isMobile ? "none" : "translateY(-4px)",
                      boxShadow: isMobile
                        ? "0 8px 32px rgba(81, 103, 246, 0.12)"
                        : "0 16px 48px rgba(81, 103, 246, 0.16), 0 2px 8px rgba(81, 103, 246, 0.08)",
                    },
                  }}
                >
                  <div
                    style={{
                      fontSize: isMobile ? "16px" : "18px",
                      fontWeight: "600",
                      width: "100%",
                      padding: isMobile ? "12px 16px" : "16px 24px",
                      background:
                        "linear-gradient(135deg, #F0F3FF 0%, #E9EDFF 100%)",
                      borderRadius: isMobile ? "12px" : "16px",
                      color: "#5167F6",
                      marginBottom: isMobile ? "16px" : "24px",
                    }}
                  >
                    Card Volume Breakdown
                  </div>
                  <CardVolumeChart
                    reportData={fee_navigator_response}
                    fee_navigator_response={fee_navigator_response}
                  />
                </div>
              </div>
            </Tile>
          </div>
          <div className="mt-4">
            <Tile
              style={{
                borderRadius: isMobile ? "16px" : "24px",
                border: "1px solid rgba(81, 103, 246, 0.08)",
              }}
            >
              <TileTitle
                style={{
                  fontSize: isMobile ? "24px" : "28px",
                  fontWeight: "800",
                  color: "#2A2A2A",
                  letterSpacing: "-0.5px",
                  position: "relative",
                  paddingBottom: isMobile ? "10px" : "28px",
                  marginBottom: isMobile ? "8px" : "36px",
                  ":after": {
                    content: '""',
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    width: "80px",
                    height: "4px",
                    background:
                      "linear-gradient(90deg, #2A2A2A 0%, #4A4A4A 100%)",
                    borderRadius: "2px",
                  },
                }}
              >
                Account Fees
              </TileTitle>
              <div className={isMobile ? "mt-3" : "mt-4"}>
                <div
                  style={{
                    background: "rgba(255, 255, 255, 0.9)",
                    backdropFilter: "blur(12px)",
                    maxWidth: "800px",
                    margin: "0 auto",
                    borderRadius: isMobile ? "16px" : "24px",
                    padding: isMobile ? "20px" : "32px",
                    boxShadow:
                      "0 8px 32px rgba(81, 103, 246, 0.12), 0 2px 6px rgba(81, 103, 246, 0.06)",
                    transition:
                      "all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
                    border: "1px solid rgba(81, 103, 246, 0.12)",
                    ":hover": {
                      transform: isMobile ? "none" : "translateY(-4px)",
                      boxShadow: isMobile
                        ? "0 8px 32px rgba(81, 103, 246, 0.12)"
                        : "0 16px 48px rgba(81, 103, 246, 0.16), 0 2px 8px rgba(81, 103, 246, 0.08)",
                    },
                  }}
                >
                  <div
                    style={{
                      fontSize: isMobile ? "16px" : "18px",
                      fontWeight: "600",
                      width: "100%",
                      padding: isMobile ? "12px 16px" : "16px 24px",
                      background:
                        "linear-gradient(135deg, #F0F3FF 0%, #E9EDFF 100%)",
                      borderRadius: isMobile ? "12px" : "16px",
                      color: "#5167F6",
                      marginBottom: isMobile ? "16px" : "24px",
                    }}
                  >
                    Account Monthly Service Fees
                  </div>
                  <AccountFeesTable reportData={fee_navigator_response} />
                </div>
              </div>
            </Tile>
          </div>
        </div>
      </Root>

      <LearnHowModal
        open={showLearnHowModal}
        onClose={() => setShowLearnHowModal(false)}
        onAgree={handleAgree}
      />
    </div>
  );
};

const renderCustomizedLabel = (props, negotiable) => {
  console.log("negotiable", negotiable);
  const { x, y, width, height, value } = props;
  return (
    <>
      <g>
        <text
          font-size={isMobile ? 14 : 18}
          font-weight={negotiable ? "bold" : ""}
          x={negotiable ? 17 + x + width / 6 : 5 + x + width / 6}
          y={y + height / 2}
          fill={"white"}
          dominantBaseline="middle"
        >
          {negotiable ? `Negotiable (${value}%)` : `Non-Negotiable (${value}%)`}
        </text>
      </g>
    </>
  );
};

const CardTransactionChart = ({ reportData }) => {
  const { data, label } = getBrandTransactionSplit(reportData);
  return <TransactionCountTable rows={data} label={label} />;
};

const CardVolumeChart = ({ reportData, fee_navigator_response }) => {
  const { data, label } = getBrandVolumeSplit(reportData);
  return (
    <div
      className="rombis-pie"
      style={{
        border: "1px solid #DEDFE3",
        borderRadius: "12px",
        overflow: "hidden",
        flex: 1,
        minWidth: "300px",
      }}
    >
      <RombisPieChart data={data} label={label} />
      <CardTransactionChart reportData={fee_navigator_response} />
    </div>
  );
};

const CreditDebitVolumeChart = ({ reportData }) => {
  const { data, label } = getCreditDebitSVolumeSplit(reportData);
  return <RombisPieChart data={data} label={label} />;
};

const CreditDebitTransactionChart = ({ reportData }) => {
  const { data, label } = getCreditDebitTransactionSplit(reportData);
  return <TransactionCountTable rows={data} label={label} />;
};

const RombisPieChart = ({ data, label }) => {
  const renderLabel = (cell: Object) => {
    const { cx, cy, midAngle, outerRadius, labelIcon, value, type } = cell;
    const RADIAN = Math.PI / 180;
    const radius = outerRadius + 30;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    let renderValue;
    switch (type) {
      case "Currency":
        renderValue = `$${
          value?.toLocaleString(undefined, { maximumFractionDigits: 2 }) ||
          value
        }`;
        break;
      default:
        renderValue = value;
    }
    return (
      <g>
        <image
          x={x - 12}
          y={y - 12}
          width={50}
          height={34}
          xlinkHref={labelIcon}
        />
        <LabelList dataKey="name" content={false} />
      </g>
    );
  };

  return (
    <div>
      <PieChart width={isMobile ? 350 : 500} height={isMobile ? 325 : 400}>
        <Pie
          dataKey="value"
          data={data}
          labelLine={true}
          label={renderLabel}
          innerRadius={isMobile ? 35 : 80}
          outerRadius={isMobile ? 80 : 130}
          paddingAngle={8}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
    </div>
  );
};

const TransactionCountTable = ({ rows, label, showLabel }) => {
  return (
    <div
      style={{
        borderRadius: isMobile ? "16px" : "24px",
        overflow: "hidden",
        height: "fit-content",
        flex: 1,
        minWidth: "300px",
        border: "1px solid rgba(42, 42, 42, 0.08)",
        background: "rgba(255, 255, 255, 0.9)",
        backdropFilter: "blur(12px)",
        boxShadow:
          "0 8px 32px rgba(42, 42, 42, 0.06), 0 2px 4px rgba(42, 42, 42, 0.02)",
      }}
    >
      {showLabel && (
        <div
          style={{
            fontSize: isMobile ? "15px" : "18px",
            fontWeight: "700",
            width: "100%",
            padding: isMobile ? "16px 20px" : "24px 32px",
            background: "linear-gradient(135deg, #F5F5F5 0%, #EFEFEF 100%)",
            color: "#2A2A2A",
            letterSpacing: "-0.3px",
          }}
        >
          {label}
        </div>
      )}
      <TableContainer
        component={Paper}
        style={{
          boxShadow: "none",
          background: "transparent",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                style={{
                  fontSize: isMobile ? "14px" : "18px",
                  fontWeight: "700",
                  color: "#2A2A2A",
                  letterSpacing: "-0.3px",
                  borderBottom: "2px solid rgba(42, 42, 42, 0.08)",
                  padding: isMobile ? "16px 20px" : "24px 32px",
                  background: "rgba(81, 103, 246, 0.04)",
                }}
              >
                Card Brand
              </TableCell>
              <TableCell
                align="center"
                style={{
                  fontSize: isMobile ? "14px" : "16px",
                  fontWeight: "700",
                  color: "#2A2A2A",
                  letterSpacing: "-0.3px",
                  borderBottom: "2px solid rgba(42, 42, 42, 0.08)",
                  padding: isMobile ? "16px 20px" : "24px 32px",
                  background: "rgba(81, 103, 246, 0.04)",
                }}
              >
                Count
              </TableCell>
              <TableCell
                align="center"
                style={{
                  fontSize: isMobile ? "14px" : "18px",
                  fontWeight: "700",
                  color: "#2A2A2A",
                  letterSpacing: "-0.3px",
                  borderBottom: "2px solid rgba(42, 42, 42, 0.08)",
                  padding: isMobile ? "16px 20px" : "24px 32px",
                  background: "rgba(81, 103, 246, 0.04)",
                }}
              >
                Volume
              </TableCell>
              <TableCell
                align="center"
                style={{
                  fontSize: isMobile ? "14px" : "18px",
                  fontWeight: "700",
                  color: "#2A2A2A",
                  letterSpacing: "-0.3px",
                  borderBottom: "2px solid rgba(42, 42, 42, 0.08)",
                  padding: isMobile ? "16px 20px" : "24px 32px",
                  background: "rgba(81, 103, 246, 0.04)",
                }}
              >
                Txn Fee
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={row.name}
                style={{
                  background:
                    index % 2 === 0 ? "rgba(42, 42, 42, 0.01)" : "transparent",
                  transition: "all 0.2s ease",
                  ":hover": {
                    background: "rgba(42, 42, 42, 0.03)",
                  },
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    fontSize: isMobile ? "13px" : "18px",
                    fontWeight: "500",
                    color: "#2A2A2A",
                    borderBottom: "1px solid rgba(42, 42, 42, 0.06)",
                    padding: isMobile ? "16px 20px" : "24px 32px",
                    gap: "12px",
                  }}
                >
                  <img width={42} height={26} src={row.labelIcon} alt="card" />
                  <span style={{ paddingLeft: "12px" }}>{row.name}</span>
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontSize: isMobile ? "13px" : "18px",
                    fontWeight: "600",
                    color: "#2A2A2A",
                    borderBottom: "1px solid rgba(42, 42, 42, 0.06)",
                    padding: isMobile ? "16px 20px" : "24px 32px",
                  }}
                >
                  {row.value}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontSize: isMobile ? "15px" : "18px",
                    fontWeight: "600",
                    color: "#2A2A2A",
                    borderBottom: "1px solid rgba(42, 42, 42, 0.06)",
                    padding: isMobile ? "16px 20px" : "24px 32px",
                  }}
                >
                  <span
                    style={{
                      background: "rgba(42, 42, 42, 0.04)",
                      padding: isMobile ? "4px 8px" : "6px 12px",
                      borderRadius: "6px",
                      display: "inline-block",
                      transition: "all 0.2s ease",
                      ":hover": {
                        background: "rgba(42, 42, 42, 0.06)",
                      },
                    }}
                  >
                    $
                    {typeof row.volumeValue === "number"
                      ? row.volumeValue.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : row.volumeValue}
                  </span>
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontSize: isMobile ? "15px" : "18px",
                    fontWeight: "600",
                    color: "#2A2A2A",
                    borderBottom: "1px solid rgba(42, 42, 42, 0.06)",
                    padding: isMobile ? "16px 20px" : "24px 32px",
                  }}
                >
                  <span
                    style={{
                      background: "rgba(42, 42, 42, 0.04)",
                      padding: isMobile ? "4px 8px" : "6px 12px",
                      borderRadius: "6px",
                      display: "inline-block",
                      transition: "all 0.2s ease",
                      ":hover": {
                        background: "rgba(42, 42, 42, 0.06)",
                      },
                    }}
                  >
                    {row.txnFeeValue}
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const CardFeeTable = ({ reportData }) => {
  const rows = createCardFeeTableRows(reportData);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table" style={{ fontSize: "18px" }}>
        <TableHead>
          <TableRow>
            <TableCell align="left" style={{ fontWeight: 700 }}>
              Card Brand
            </TableCell>
            <TableCell align="right">Transaction Fee</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={row.name}
              style={{
                background:
                  index % 2 === 0 ? "white" : "rgb(222, 223, 227, .25)",
              }}
            >
              <TableCell component="th" scope="row">
                <img width={42} height={26} src={row.labelIcon} alt="card" />{" "}
                <span>{row.name}</span>
              </TableCell>
              <TableCell align="right" style={{ fontWeight: 700 }}>
                {row.value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const AccountFeesTable = ({ reportData }) => {
  const rows = createAccountFeesTableRows(reportData);

  return (
    <div
      style={{
        borderRadius: isMobile ? "16px" : "24px",
        overflow: "hidden",
        height: "fit-content",
        flex: 1,
        minWidth: "300px",
        border: "1px solid rgba(42, 42, 42, 0.08)",
        background: "rgba(255, 255, 255, 0.9)",
        backdropFilter: "blur(12px)",
        boxShadow:
          "0 8px 32px rgba(42, 42, 42, 0.06), 0 2px 4px rgba(42, 42, 42, 0.02)",
      }}
    >
      <TableContainer
        component={Paper}
        style={{
          boxShadow: "none",
          background: "transparent",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                style={{
                  fontSize: isMobile ? "15px" : "18px",
                  fontWeight: "700",
                  color: "#2A2A2A",
                  letterSpacing: "-0.3px",
                  borderBottom: "2px solid rgba(42, 42, 42, 0.08)",
                  padding: isMobile ? "16px 20px" : "24px 32px",
                  background: "rgba(42, 42, 42, 0.02)",
                }}
              >
                Fee Type
              </TableCell>
              <TableCell
                align="right"
                style={{
                  fontSize: isMobile ? "15px" : "18px",
                  fontWeight: "700",
                  color: "#2A2A2A",
                  letterSpacing: "-0.3px",
                  borderBottom: "2px solid rgba(42, 42, 42, 0.08)",
                  padding: isMobile ? "16px 20px" : "24px 32px",
                  background: "rgba(42, 42, 42, 0.02)",
                }}
              >
                Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={row.name}
                style={{
                  background:
                    index % 2 === 0 ? "rgba(42, 42, 42, 0.01)" : "transparent",
                  transition: "all 0.2s ease",
                  ":hover": {
                    background: "rgba(42, 42, 42, 0.03)",
                  },
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    fontSize: isMobile ? "15px" : "18px",
                    fontWeight: "500",
                    color: "#2A2A2A",
                    borderBottom: "1px solid rgba(42, 42, 42, 0.06)",
                    padding: isMobile ? "16px 20px" : "24px 32px",
                  }}
                >
                  {row.name}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    fontSize: isMobile ? "15px" : "18px",
                    fontWeight: "600",
                    color: "#2A2A2A",
                    borderBottom: "1px solid rgba(42, 42, 42, 0.06)",
                    padding: isMobile ? "16px 20px" : "24px 32px",
                  }}
                >
                  <span
                    style={{
                      background: "rgba(42, 42, 42, 0.04)",
                      padding: isMobile ? "4px 8px" : "6px 12px",
                      borderRadius: "6px",
                      display: "inline-block",
                      transition: "all 0.2s ease",
                      ":hover": {
                        background: "rgba(42, 42, 42, 0.06)",
                      },
                    }}
                  >
                    {typeof row.value === "number"
                      ? row.value.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : row.value}
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Report;
