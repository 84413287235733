import { FunctionComponent } from "react";
import {
  Root,
  TagLine,
  Title,
  InfoText,
  UploadContainer,
  UploadArea,
  FeatureList,
  FeatureItem,
} from "./Hero.styled";
import UploadBox from "../UploadBox/UploadBox";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import PaidIcon from "@mui/icons-material/Paid";
import VerifiedIcon from "@mui/icons-material/Verified";
import LockIcon from "@mui/icons-material/Lock";

const Hero: FunctionComponent = () => (
  <Root>
    <TagLine>AI-Powered Statement Analysis</TagLine>
    <div className="mb-4">
      <Title>
        Understand Your Processing Fees{" "}
        <span
          style={{
            background: "linear-gradient(90deg, #5167F6 0%, #7186FF 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          in Seconds
        </span>
      </Title>
    </div>
    <InfoText>
      Stop overpaying on credit card processing. Our AI technology analyzes your
      merchant statement, identifies hidden fees, and helps you make informed
      decisions to reduce costs.
    </InfoText>

    <FeatureList>
      <FeatureItem>
        <AutoGraphIcon style={{ color: "#5167F6" }} />
        <span>Instant AI Analysis</span>
      </FeatureItem>
      <FeatureItem>
        <PaidIcon style={{ color: "#10B981" }} />
        <span>Find Hidden Savings</span>
      </FeatureItem>
      <FeatureItem>
        <VerifiedIcon style={{ color: "#FF6B6B" }} />
        <span>100% Free</span>
      </FeatureItem>
      <FeatureItem>
        <LockIcon style={{ color: "#6B7280" }} />
        <span>Bank-Level Security</span>
      </FeatureItem>
    </FeatureList>

    <UploadContainer>
      <UploadArea>
        <UploadBox />
      </UploadArea>
    </UploadContainer>
  </Root>
);

export default Hero;
