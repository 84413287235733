import { Root, HeroWrapper } from "./Home.styled";
import Navbar from "../../components/Navbar/Navbar";
import Hero from "../../components/Hero/Hero";
import Pricing from "../../components/Pricing/Pricing";
import Footer from "../../components/Footer/Footer";

function Home() {
  return (
    <Root>
      <HeroWrapper>
        <Navbar />
        <Hero />
      </HeroWrapper>
      <Pricing />
      <Footer />
    </Root>
  );
}

export default Home;
