import { Container } from "react-bootstrap";
import styled from "styled-components";

export const Root = styled(Container)`
  margin-top: 40px;
  max-width: 1150px;
  min-height: 40vh;
  font-size: 40px;
  padding: 40px;
  display: flex;
  color: black;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 8px 64px rgba(143, 143, 143, 0.16);

  @media (max-width: 600px) {
    padding: 10px;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 48px;
  color: #131316;
`;

export const TileTitle = styled.div`
  font-size: 22px;
  color: black;
  font-weight: 600;
  padding-bottom: 5px;
  border-bottom: 1px solid rgb(222, 223, 227);
`;

export const Tile = styled.div`
  border-radius: 9px;
  padding: 30px;
  box-shadow: 0px 8px 30px rgba(143, 143, 143, 0.16);

  @media (max-width: 600px) {
    padding: 10px;
  }
`;
