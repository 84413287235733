import { FunctionComponent, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ClipLoader } from "react-spinners";
import { uploadAPI } from "../../api/upload";
import { updateReportAPI } from "../../api/report";
import ErrorModal from "./ErrorModal";
import {
  StandardButton,
  Root,
  Text,
  FileBox,
  SuccessIcon,
  SuccessText,
} from "./UploadBox.styled";
import BrowseIcon from "../../assets/browse.svg";
import CheckMark from "../../assets/green-checkmark.svg";
import FileIcon from "../../assets/file.svg";
import LargeBrowse from "../../assets/large-browse.svg";
import CancelIcon from "../../assets/cancel.svg";
import InfoFormModal from "./InfoModal/InfoFormModal";
import styled from "styled-components";

const StyledRoot = styled(Root)`
  background: linear-gradient(180deg, #ffffff 0%, #f8f9ff 100%);
  border-radius: 24px;
  border: 1px solid rgba(81, 103, 246, 0.08);
  padding: 40px;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: 0 8px 32px rgba(81, 103, 246, 0.08),
    0 2px 4px rgba(81, 103, 246, 0.04);
  backdrop-filter: blur(12px);

  &.boxdrop-active {
    border: 2px dashed #5167f6;
    background: linear-gradient(180deg, #f8f9ff 0%, #f0f3ff 100%);
    box-shadow: 0 12px 40px rgba(81, 103, 246, 0.12);
  }

  &:hover {
    box-shadow: 0 12px 40px rgba(81, 103, 246, 0.12);
  }
`;

const StyledText = styled(Text)`
  font-size: 16px;
  font-weight: 500;
  color: #666;
  margin: 8px 0;
`;

const StyledSuccessText = styled(SuccessText)`
  font-size: 28px;
  font-weight: 800;
  background: linear-gradient(90deg, #5167f6 0%, #7186ff 70%, #8b9fff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 16px 0;
  letter-spacing: -0.5px;
`;

const StyledFileBox = styled(FileBox)`
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(12px);
  border-radius: 16px;
  padding: 16px 24px;
  border: 1px solid rgba(81, 103, 246, 0.12);
  box-shadow: 0 4px 20px rgba(81, 103, 246, 0.08);
  transition: all 0.3s ease;

  &:hover {
    transform: translateX(4px);
    background: rgba(255, 255, 255, 0.95);
  }

  button {
    padding: 8px;
    border-radius: 8px;
    transition: all 0.2s ease;

    &:hover {
      background: rgba(81, 103, 246, 0.08);
    }
  }
`;

const StyledButton = styled(StandardButton)`
  background: linear-gradient(90deg, #5167f6 0%, #7186ff 100%);
  border-radius: 12px;
  padding: 12px 24px;
  font-weight: 600;
  text-transform: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(81, 103, 246, 0.2);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(81, 103, 246, 0.3);
  }

  img {
    margin-right: 8px;
  }
`;

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  img {
    width: 64px;
    height: 64px;
    filter: drop-shadow(0 4px 12px rgba(81, 103, 246, 0.2));
  }
`;

const UploadBox: FunctionComponent = () => {
  const [pdfFile, setPdfFile] = useState<File | null>(null);
  const [generateReportLoading, setGenerateReportLoading] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);
  const [infoModalOpen, setInfoModalOpen] = useState<boolean>(false);
  const [rateLimitError, setRateLimitError] = useState<boolean>(false);
  const [reportCreated, setReportCreated] = useState<boolean>(false);
  const [reportId, setReportId] = useState<String | null>(null);
  const [requiresManualReview, setRequiresManualReview] =
    useState<boolean>(false);
  //const [checkoutUrl, setCheckoutUrl] = useState<String>('');

  let response: any = null;

  const onDrop = useCallback((acceptedFiles: any) => {
    // Do something with the accepted files, e.g. upload to server
    //onUpload(acceptedFiles);
    const file = acceptedFiles[0];
    setPdfFile(file);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const cancelUpload = () => {
    setPdfFile(null);
  };

  // This function is run after user submits their information form
  // We attempt to update their report document with their info and then
  // send them to their report page
  const handleFormSubmission = async (formData: any) => {
    // Hit our PUT report endpoint
    const { email, name = "", phoneNumber = "" } = formData;
    try {
      //
      if (!!reportId)
        response = await updateReportAPI(reportId, {
          email,
          name,
          phone: phoneNumber,
        });
      // If for some reason a user has gotten to the form modal without a report created yet open the error modal
      else {
        setErrorModalOpen(true);
      }

      window.location.href = `/report/${reportId}`;
    } catch (error: any) {
      console.log("error:", error);
      setReportCreated(false);
      if (error?.response?.data?.errorCode === "MRR") {
        setRequiresManualReview(true);
      }
      // Updating the report with the users information has failed
      // TODO: consider passing error messages to the modal since now we have multiple error scenarios
      setErrorModalOpen(true);
    }
  };

  const createReport = async () => {
    setGenerateReportLoading(true);
    setRequiresManualReview(false);
    setRateLimitError(false);
    try {
      const formData = new FormData();
      formData.append("file", pdfFile as Blob);
      response = await uploadAPI(formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setReportId(response.data._report);

      // setCheckoutUrl(response.data.url);
    } catch (error: any) {
      console.log(error);
      if (error?.response?.data?.errorCode === "MRR") {
        setRequiresManualReview(true);
      } else if (error.response.status === 429) {
        setRateLimitError(true);
      }
      setErrorModalOpen(true);
    }
    setGenerateReportLoading(false);

    // Report successfully created
    if (response.status === 200) {
      setReportCreated(true);
    }
  };

  if (reportCreated)
    return (
      <StyledRoot>
        <SuccessContainer>
          <SuccessIcon src={CheckMark} />
          <StyledSuccessText>Successfully created analysis</StyledSuccessText>
          <StyledText>
            Fill out information to access your FREE analysis
          </StyledText>
          <StyledButton
            variant="contained"
            onClick={() => setInfoModalOpen(true)}
          >
            Next
          </StyledButton>
        </SuccessContainer>
        <InfoFormModal
          open={infoModalOpen}
          onClose={() => setInfoModalOpen(false)}
          handleSubmit={handleFormSubmission}
        />
      </StyledRoot>
    );

  if (pdfFile)
    return (
      <StyledRoot>
        {generateReportLoading ? (
          <StyledText>
            It may take up to a minute to generate your report...
          </StyledText>
        ) : (
          <StyledSuccessText>File uploaded and ready</StyledSuccessText>
        )}

        <StyledFileBox>
          <img src={FileIcon} alt="" style={{ padding: "1px 6px" }} />
          <StyledText>{pdfFile.name}</StyledText>
          <button onClick={cancelUpload}>
            <img src={CancelIcon} alt="" />
          </button>
        </StyledFileBox>
        <StyledButton
          onClick={createReport}
          variant="contained"
          style={{ marginTop: "24px" }}
        >
          {generateReportLoading ? (
            <ClipLoader color="white" loading={true} size={24} />
          ) : (
            "Create Report"
          )}
        </StyledButton>
        <ErrorModal
          open={errorModalOpen}
          onClose={() => {
            cancelUpload();
            setErrorModalOpen(false);
          }}
          rateLimitError={rateLimitError}
          requiresManualReview={requiresManualReview}
        />
      </StyledRoot>
    );

  return (
    <StyledRoot
      {...getRootProps()}
      className={`${isDragActive ? "boxdrop-active" : ""}`}
    >
      <img
        src={LargeBrowse}
        alt=""
        height={118}
        style={{
          marginBottom: "24px",
          filter: "drop-shadow(0 4px 12px rgba(81, 103, 246, 0.2))",
        }}
      />
      <input {...getInputProps()} accept=".pdf" />
      {isDragActive ? (
        <StyledText>Drop the PDF file here ...</StyledText>
      ) : (
        <StyledText>
          Drag and drop a PDF file here or browse to select a file
        </StyledText>
      )}

      <StyledButton
        variant="contained"
        startIcon={<img src={BrowseIcon} alt="" />}
        style={{ marginTop: "24px" }}
      >
        Browse
      </StyledButton>
      <ErrorModal
        open={errorModalOpen}
        onClose={() => setErrorModalOpen(false)}
        requiresManualReview={requiresManualReview}
        rateLimitError={rateLimitError}
      />
    </StyledRoot>
  );
};

export default UploadBox;
